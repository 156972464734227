import React, { useEffect, useState, useRef } from "react";
import { useCanvas } from "../../context/CanvasContext";
import { connect } from "react-redux";
import { BiImport } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import { Col, Container, Row, Button, Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import { showLoader } from "../../redux/actions/commonActions";
import { getTemplateSize, getTemplate } from "../../redux/actions/templateActions";
import SaveTemplateModal from "../../components/modals/SaveTemplateModal";
import CanvasErrorModal from "../../components/modals/CanvasErrorModal";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import PreviewTemplateModal from "../../components/modals/PreviewTemplateModal";
import { rgbToHex } from "../../utils/colorUtils";
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { toast } from "react-toastify";
import { SOMETHING_WENT_WRONG, UNAUTHORIZED_ACTION } from "../../utils/errorMessages";

const Canvas = ({ templateData = {}, getTemplateSize, showLoader, getTemplate, selectedTemplate, isEditFlow }) => {
  const {
    canvasRef,
    changeCPNFontColor,
    changeCPNTextAlignment,
    changeCPIFontColor,
    changeAdditionalFieldsFontColor,
    changeCPNFontSize,
    changeCPIFontSize,
    changeAdditionalFieldsFontSize,
    prepareCanvas,
    startDrawing,
    finishDrawing,
    onClick,
    draw,
    pdfCanvasRef,
    leftPaneCanvasRef,
    validate,
    restCanvas,
    importPDF,
  } = useCanvas();

  const hiddenFileInput = useRef(null);
  const [show, setShow] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showTemplatePreviewModal, setShowTemplatePreviewModal] = useState(false);
  const [errorText, setErrorTest] = useState("");
  const [selectedTemplateForEdit, setSelectedTemplateForEdit] = useState(null);

  const [cpnFontSize, setCPNFontSize] = useState(16);
  const [cpiFontSize, setCPIFontSize] = useState(16);
  const [additionalFieldsFontSize, setAdditionalFieldsFontSize] = useState(16);
  const [cpnFontColor, setCPNFontColor] = useState("#000000");
  const [cpnTextAlignment, setCPNTextAlignment] = useState('Left');
  const [cpiFontColor, setCPIFontColor] = useState("#000000");
  const [additionalFieldsFontFontColor, setAdditionalFieldsFontColor] = useState("#000000");
  const [isPdfUpladed, setIsPDFUploaded] = useState(false);
  const [runTour, setRunTour] = useState(false);
  const [runTourAfterPDFUpload, setRunTourAfterPDFUpload] = useState(false);
  const [stepIndexOne, setStepIndexOne] = useState(0);
  const [stepIndexTwo, setStepIndexTwo] = useState(0);
  const [showToolbarOverlay, setShowToolbarOveraly] = useState(false);


  const [steps] = useState([{
    target: '.m-btn',
    content: 'To get started import a pdf file',
    disableBeacon: true,
    placement: "right"
  }
  ]);
  const [alignmentOptions] = useState([
    {value: 'Left', text: 'Left'},
    {value: 'Center', text: 'Center'},
    {value: 'Right', text: 'Right'}])
  
  const [afterPDFImportSteps] = useState([
    {
      target: '#left-pane-canvas',
      content: 'Drag and drop items from the left pane to position it on the template',
      placement: "auto",
      disableBeacon: true,
      styles: {
        options: {
          zIndex: 900,
        }
      }
    },
    {
      target: '#draw-canvas',
      content: 'Editor area. You can drag any item form the left pane',
      placement: "bottom",
      disableBeacon: true,
      styles: {
        options: {
          zIndex: 900,
        }
      }
    },
    {
      target: '#tool-bar-cpi',
      content: 'You can set the color and the font size for the credit party identifier',
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: '#tool-bar-cpn',
      content: 'You can set the color and the font size for the credit party name',
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: '#tool-bar-additionalFields',
      content: 'You can set the color and the font size for the additional fields',
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: '#btn-reset',
      content: 'You can reset the editor by clicking on "Reset". This will reset the imported pdf and will move the draggable items to its original position',
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: '#btn-view',
      content: 'Use this to get a preview of the template',
      placement: "bottom",
      disableBeacon: true,
    },
    {
      title: "Last Step",
      target: '#btn-save',
      content: 'Once you are done with positioning you can click "Save" to add the template name and help text',
      placement: "bottom",
      disableBeacon: true,
      locale: { last: 'Finish' }
    }
  ]);

  useEffect(() => {
    if (isPdfUpladed) {
      setStepIndexOne(1);
      setRunTour(true);
      setRunTourAfterPDFUpload(true);
    }
  }, [isPdfUpladed])

  useEffect(() => {
    if (isEditFlow) {
      setRunTour(false);
      async function getTemplateAsync() {
        showLoader(true);
        const result = await getTemplate(selectedTemplate.id);
        // remove the fields duplicates
        
        templateData.newlyAddedFields.forEach(field => {
          const index = result.data.additionalFields.findIndex(x => x.mpaKey === field.mpaKey);
          if (index > -1) {
            result.data.additionalFields.splice(index, 1);
          }
        })

        templateData.fullyRemovedFields.forEach(field => {
          const index = result.data.additionalFields.findIndex(x => x.mpaKey === field.mpaKey);
          if (index > -1) {
            result.data.additionalFields.splice(index, 1);
          }
        })

        prepareCanvas(result.data, templateData.cpiLength, templateData.newlyAddedFields, templateData.removedFields);
        const cpiColor = result.data.configurations.cpiFontColor;
        const cpnColor = result.data.configurations.cpnFontColor;
        if (result.data.additionalFields.length > 0) {
          const additionalDataFontColor = result.data.additionalFields[0].configuration.fontColor;
          setAdditionalFieldsFontColor(rgbToHex(additionalDataFontColor.r, additionalDataFontColor.g, additionalDataFontColor.b));
          setAdditionalFieldsFontSize(result.data.additionalFields[0].configuration.fontSize);
        }
        setCPIFontColor(rgbToHex(cpiColor.r, cpiColor.g, cpiColor.b));
        setCPNFontColor(rgbToHex(cpnColor.r, cpnColor.g, cpnColor.b));
        setCPNTextAlignment(result.data.configurations.cpnTextAlignment);
        setCPIFontSize(result.data.configurations.cpiFontSize);
        setCPNFontSize(result.data.configurations.cpnFontSize);

        setIsPDFUploaded(true);
        setSelectedTemplateForEdit(result.data);
        showLoader(false);
      }
      getTemplateAsync();
    } else {
      setRunTour(true);
      prepareCanvas(null);

    }
  }, [prepareCanvas, isEditFlow, getTemplate, showLoader, selectedTemplate]);

  const toggleShow = () => setShow((p) => !p);
  const toggleShowErrorModal = () => setShowErrorModal((p) => !p);
  const toggleShowConfirmationModal = () => setShowConfirmationModal((p) => !p);
  const toggleTemplatePreviewModal = () => {
    setShowTemplatePreviewModal((p) => !p)
  };

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      setStepIndexOne(index + (action === ACTIONS.PREV ? -1 : 1));
    }
    else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setRunTour(false);
    }
  };


  const handleJoyrideCallbackAfterImport = (data) => {
    const { action, index, status, type } = data;

    if (index < 2 && [STATUS.RUNNING].includes(status)) {
      setShowToolbarOveraly(true);
    } else {
      setShowToolbarOveraly(false);
    }

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      setStepIndexTwo(index + (action === ACTIONS.PREV ? -1 : 1));
    }
    else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setShowToolbarOveraly(false);
      setRunTourAfterPDFUpload(false);
    }
    else if (ACTIONS.CLOSE.includes(action) || action === 'close') {
      setRunTourAfterPDFUpload(false);
    }
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleSaveTemplate = () => {
    var validObj = validate();
    if (validObj.isValid) {
      toggleShow();
    } else {
      setErrorTest(validObj.error);
      toggleShowErrorModal();
    }
  };

  const handleResetCanvas = () => {
    setIsPDFUploaded(false);
    setCPNFontSize(16);
    setCPIFontSize(16);
    setAdditionalFieldsFontSize(16);
    setCPIFontColor("#000000");
    setCPNFontColor("#000000");
    setCPNTextAlignment('Left')
    setAdditionalFieldsFontColor("#000000");
    restCanvas();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    const payload = { file: fileUploaded, market: templateData.market };

    if (fileUploaded.type !== "application/pdf") {
      setErrorTest("You can only upload PDF files");
      toggleShowErrorModal();
      return;
    }
    showLoader(true);
    getTemplateSize(payload)
      .then((res) => {
        importPDF(
          fileUploaded,
          templateData.validations,
          templateData.additionalFields,
          res.data.docId
        );
        setIsPDFUploaded(true);
        setCPNFontSize(16);
        setCPIFontSize(16);
        setCPIFontColor("#000000");
        setCPNFontColor("#000000");
        setCPNTextAlignment('Left')
        setTimeout(function () {
          showLoader(false);
        }, 2000);
      })
      .catch(e => {
        if (e.response) {
          if (e.response.status) {
            if (e.response.status === 403) {
              toast.error(UNAUTHORIZED_ACTION);
            } else {
              toast.error(SOMETHING_WENT_WRONG)
            }
          } else if (e.response.data.description) {
            toast.error(e.response.data.description);
          }
        } else {
          toast.error(SOMETHING_WENT_WRONG);
        }
      });
    event.target.value = "";
  };

  const handleCPIFontSizeChange = (size) => {
    changeCPIFontSize(size);
    setCPIFontSize(size);
  };

  const handleCPNFontSizeChange = (size) => {
    changeCPNFontSize(size);
    setCPNFontSize(size);
  };

  const handleChangeAdditionalFieldsFontSize = (size) => {
    changeAdditionalFieldsFontSize(size);
    setAdditionalFieldsFontSize(size);
  };

  const handleCPNFontColorChange = (color) => {
    setCPNFontColor(color);
    changeCPNFontColor(color);
  };
  const handleCPNTextAlignmentChange = (alignment) => {
    console.log('aaaaa', alignment)
    setCPNTextAlignment(alignment);
    changeCPNTextAlignment(alignment);
  };

  const handleCPIFontColorChange = (color) => {
    setCPIFontColor(color);
    changeCPIFontColor(color);
  };

  const handleChangeAdditionalFieldsFontColor = (color) => {
    setAdditionalFieldsFontColor(color);
    changeAdditionalFieldsFontColor(color);
  };


  return (
    <Container id="canvas-page">
      <Joyride
        run={runTour}
        callback={handleJoyrideCallback}
        steps={steps}
        showSkipButton={true}
        hideCloseButton={true}
        disableScrolling={true}
        stepIndex={stepIndexOne}
        styles={{
          options: {
            arrowColor: '#e3ffeb',
            backgroundColor: '#fff',
            primaryColor: '#63AB45',
            textColor: '#3b3b3b',

            width: 300,
            zIndex: 1000,
          }
        }}
      />
      <Joyride
        run={runTourAfterPDFUpload}
        callback={handleJoyrideCallbackAfterImport}
        steps={afterPDFImportSteps}
        showSkipButton={true}
        hideCloseButton={true}
        disableScrolling={true}
        disableScrollParentFix={true}
        stepIndex={stepIndexTwo}
        continuous
        styles={{
          options: {
            arrowColor: '#e3ffeb',
            backgroundColor: '#fff',
            primaryColor: '#63AB45',
            textColor: '#3b3b3b',
            width: 300,
            zIndex: 1000,
          }
        }}
      />
      <SaveTemplateModal show={show} toggleShow={toggleShow} template={selectedTemplateForEdit} isEditFlow={isEditFlow} />
      <CanvasErrorModal
        show={showErrorModal}
        toggleShow={toggleShowErrorModal}
        errorText={errorText}
      />
      <ConfirmationModal
        show={showConfirmationModal}
        toggleShow={toggleShowConfirmationModal}
        confirm={handleResetCanvas}
        title="Are you sure you want to reset? all your progress will be lost"
      />
      {showTemplatePreviewModal ? (
        <PreviewTemplateModal
          show={showTemplatePreviewModal}
          toggleShow={toggleTemplatePreviewModal}
        >
        </PreviewTemplateModal>) : null}
      <>

        <Row>
          <Col xl="12" lg="8" md="12" sm="12">
            <div className="card">
              {showToolbarOverlay ? (<div className="toolbar-overlay wrap-content d-flex"></div>) : null}
              <div className="wrap-content d-flex canvas-content">
                <Button id="btn-import-pdf" className="m-btn mx-3" onClick={() => handleClick()}>
                  <BiImport />
                  <span className="hide">Import PDF</span>
                </Button>
                <div className="v-border"></div>

                <div className="wrap-group" id="tool-bar-cpi">
                  <Form.Label className="bold" htmlFor="cpiNameLabel">
                    Credit Party Identifier (CPI)
                  </Form.Label>
                  <div className="group">
                    <Form.Label htmlFor="cpiFontSize">font size</Form.Label>
                    <Form.Control
                      id="cpiFontSize"
                      className="fonts"
                      type="number"
                      value={cpiFontSize}
                      min="1"
                      disabled={!isPdfUpladed}
                      onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                      onChange={(e) =>
                        handleCPIFontSizeChange(parseInt(e.target.value))
                      }
                    ></Form.Control>

                    <Form.Label className="color-lbl" htmlFor="cpiColor">
                      color
                    </Form.Label>
                    <Form.Control
                      onChange={(e) => handleCPIFontColorChange(e.target.value)}
                      type="color"
                      value={cpiFontColor}
                      disabled={!isPdfUpladed}
                      className="colors"
                      id="cpiColor"
                      title="Choose your color"
                    />
                  </div>
                </div>
                {templateData.displayCPNInTemplate === true &&
                  <>
                    <div className="v-border"></div>
                    <div className="wrap-group" id="tool-bar-cpn">
                      <Form.Label className="bold" htmlFor="cpnNameLabel">
                        Credit Party Name (CPN)
                      </Form.Label>

                      <div className="group">
                        <Form.Label htmlFor="cpnFontSize">font size</Form.Label>
                        <Form.Control
                          id="cpnFontSize"
                          className="fonts"
                          type="number"
                          min="1"
                          value={cpnFontSize}
                          disabled={!isPdfUpladed}
                          onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                          onChange={(e) =>
                            handleCPNFontSizeChange(parseInt(e.target.value))
                          }
                        ></Form.Control>

                        <Form.Label className="color-lbl" htmlFor="cpnColorPicker">
                          color
                        </Form.Label>
                        <Form.Control
                          onChange={(e) => handleCPNFontColorChange(e.target.value)}
                          type="color"
                          value={cpnFontColor}
                          disabled={!isPdfUpladed}
                          className="colors"
                          id="cpnColorPicker"
                          title="Choose your color"
                        />
                        <div>
                          <div className="d-flex pt-1 space-add">

                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-left">
                                Align Left
                              </Tooltip>
                            }
                          >
                            <label className="btn-align px-1">
                            <input type="radio" name="r-btn" value="Left" checked={ cpnTextAlignment === 'Left'} 
                              onChange={(e) => handleCPNTextAlignmentChange(e.target.value)}/>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path d="M3,3H21V5H3V3M3,7H15V9H3V7M3,11H21V13H3V11M3,15H15V17H3V15M3,19H21V21H3V19Z" />
                                </svg>
                            </label>
                            </OverlayTrigger>
                            <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-center">
                                Align Center
                              </Tooltip>
                            }
                          >
                            <label className="btn-align px-1">
                            <input type="radio" name="r-btn" value="Center" checked={ cpnTextAlignment === 'Center'}
                              onChange={(e) => handleCPNTextAlignmentChange(e.target.value)}/>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path d="M3,3H21V5H3V3M7,7H17V9H7V7M3,11H21V13H3V11M7,15H17V17H7V15M3,19H21V21H3V19Z" />
                                </svg>

                            </label>
                            </OverlayTrigger>
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-right">
                                  Align Right
                                </Tooltip>
                              }
                            >
                            <label className="btn-align px-1">
                            <input type="radio" name="r-btn" value="Right" checked={ cpnTextAlignment === 'Right'}
                              onChange={(e) => handleCPNTextAlignmentChange(e.target.value)}/>

                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path d="M3,3H21V5H3V3M9,7H21V9H9V7M3,11H21V13H3V11M9,15H21V17H9V15M3,19H21V21H3V19Z" />
                                </svg>

                            </label>
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                    </div></>}
                <div className="v-border"></div>
                <div className="wrap-group" id="tool-bar-additionalFields">
                  <Form.Label className="bold" htmlFor="additionalFieldsNameLabel">
                    Additional Fields
                  </Form.Label>

                  <div className="group">
                    <Form.Label htmlFor="additionalFieldsFontSize">font size</Form.Label>
                    <Form.Control
                      id="additionalFieldsFontSize"
                      className="fonts"
                      type="number"
                      min="1"
                      value={additionalFieldsFontSize}
                      disabled={!isPdfUpladed}
                      onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                      onChange={(e) =>
                        handleChangeAdditionalFieldsFontSize(parseInt(e.target.value))
                      }
                    ></Form.Control>

                    <Form.Label className="color-lbl" htmlFor="additionalFieldsColorPicker">
                      color
                    </Form.Label>
                    <Form.Control
                      onChange={(e) => handleChangeAdditionalFieldsFontColor(e.target.value)}
                      type="color"
                      value={additionalFieldsFontFontColor}
                      disabled={!isPdfUpladed}
                      className="colors"
                      id="additionalFieldsColorPicker"
                      title="Choose your color"
                    />
                  </div>
                </div>

                <div className="v-border"></div>
                <Button
                  id="btn-reset"
                  className="m-btn mx-4 btn-cancel"
                  disabled={!isPdfUpladed}
                  onClick={toggleShowConfirmationModal}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-clockwise"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                    />
                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                  </svg>
                  <span className="hide"> Reset </span>
                </Button>{" "}
                <Button
                  id="btn-view"
                  className="m-btn mx-1 btn-control"
                  disabled={!isPdfUpladed}
                  onClick={() => toggleTemplatePreviewModal()}
                >
                  <AiOutlineEye />
                  <span className="hide">View</span>
                </Button>{" "}
                <Button
                  id="btn-save"
                  className="m-btn mx-1 btn-control"
                  disabled={!isPdfUpladed}
                  onClick={handleSaveTemplate}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-save"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z" />
                  </svg>
                  <span className="hide">Save </span>
                </Button>{" "}
                <input
                  ref={hiddenFileInput}
                  onChange={handleChange}
                  type="file"
                  style={{ display: "none" }}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div id="canvas-container">
              <canvas id="left-pane-canvas" ref={leftPaneCanvasRef} ></canvas>
              <canvas id="pdf-canvas" className="canvas" ref={pdfCanvasRef} />
              <canvas
                id="draw-canvas"
                className="canvas"
                onMouseDown={startDrawing}
                onMouseUp={finishDrawing}
                onMouseMove={draw}
                onClick={onClick}
                ref={canvasRef}
              />
            </div>
          </Col>
        </Row>
      </>
    </Container >
  );
};
const mapStateToProps = (state) => {
  return {
    templateData: state.templates.createTemplateData,
    selectedTemplate: state.templates.current,
    isEditFlow: state.templates.flow === "EDIT" ? true : false
  };
};

const mapDispatchToProps = (dispatch) => ({
  getTemplateSize: (data) => dispatch(getTemplateSize(data)),
  getTemplate: (templateId) => dispatch(getTemplate(templateId)),
  showLoader: (data) => dispatch(showLoader(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Canvas);
