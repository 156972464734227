export const authConfig = {
  authority: process.env.REACT_APP_AUTH_URL,
  client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_REDIRECT_URI,
  post_logout_redirect_uri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
  response_type: "code",
  automaticSilentRenew: false,
  scope: process.env.REACT_APP_SCOPE,
  state: "32be7a85354b4642b45a5f19fe7001b7",
  loadUserInfo: true,
  logout_uri: process.env.REACT_APP_LOGOUT_URI,
  session_expire_url: process.env.REACT_APP_SESSION_EXPIRE_URI,
  monitorSession: false,
  silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URI,
  accessTokenExpiringNotificationTime: 20,
  metadata: {
    authorization_endpoint: process.env.REACT_APP_AUTHORIZATION_ENDPOINT,
    issuer: process.env.REACT_APP_ISSUER,
    jwks_uri: process.env.REACT_APP_JWKS_URI,
    end_session_endpoint: process.env.REACT_APP_END_SESSION_ENDPOINT,
    userinfo_endpoint: process.env.REACT_APP_USERINFO_ENDPOINT,
    token_endpoint: process.env.REACT_APP_TOKEN_ENDPOINT
  },
};